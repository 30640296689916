import React, {useRef, useEffect} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Champions = (data) => {
  const championsScreen = data.data;
  const containerRef = useRef(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const carousel = carouselRef.current;
    let scrollWidth = carousel.scrollWidth;
    let containerWidth = container.offsetWidth;
    let endScroll;

    // Function to determine mobile layout
    function isMobile() {
      return window.innerWidth <= 768;
    }

    // Calculate scroll values dynamically
    function calculateValues() {
      scrollWidth = carousel.scrollWidth; // Update scroll width
      containerWidth = container.offsetWidth; // Update container width
      endScroll = isMobile() ? scrollWidth - containerWidth + 1000 : scrollWidth - containerWidth; // Set endScroll value
    }

    calculateValues();  // Initial calculation

    // Create GSAP timeline
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        pin: true,             // Pin the container while scrolling
        pinSpacing: true,      // Space out the pinned container
        scrub: 0.1,            // Smooth scrubbing
        start: 'top top',      // Start when the top of the container hits the top of the viewport
        end: () => `+=${endScroll}`,  // Dynamic scroll distance
        markers: false,        // Disable markers
        invalidateOnRefresh: true,  // Recalculate on resize
        onRefresh: calculateValues  // Recalculate values on refresh
      }
    });

    // Add horizontal scroll animation to the timeline
    tl.to(carousel, {
      x: () => -(scrollWidth - containerWidth),  // Animate horizontal scroll
      ease: 'none'   // No easing for smooth scrolling
    });

    // Optional: Add a delay or placeholder for future animations
    tl.set({}, {}, "+=0.4");

    // Cleanup function to remove ScrollTrigger instance on component unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);


  return (
    <div ref={containerRef} className='makerlab-section makerlab-section-champions-wrapper'>
      <ul ref={carouselRef} className="makerlab-carousel-wrapper">
          {championsScreen.fields.makerLabChampions.map((item, index) => {
          return (
              <li key={index} className="makerlab-carousel-item">
                <div className='carousel-overlay'></div>
                <div className="makerlab-carousel-item--bottom">
                    {item.fields?.profilePicture?.fields?.file?.url &&
                      <img src={item.fields.profilePicture.fields.file.url} alt={item.fields.profilePicture.fields.file.fileName} />
                    }

                    <span className='makerlab-carousel-item-text-column' data-dark={item.fields.textDarkMode}>
                      <span className="makerlab-carousel-item-text makerlab-carousel-item-text--bold">{item.fields.name}</span>
                      <span className="makerlab-carousel-item-text">{item.fields.title}</span>
                      {item.fields?.linkedIn?.length > 0 &&
                        <a href={item.fields.linkedIn} target='_blank'>
                          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 32">
                            <g>
                              <path d="M15.5.64C6.94.64 0 7.58 0 16.14c0 8.561 6.94 15.5 15.5 15.5 8.56 0 15.5-6.939 15.5-15.5C31 7.58 24.06.64 15.5.64Zm-4.135 22.994H7.96V12.64h3.404v10.995ZM9.647 11.2a2.02 2.02 0 0 1-2.013-2.03 2.02 2.02 0 0 1 2.013-2.028 2.02 2.02 0 0 1 2.012 2.029A2.02 2.02 0 0 1 9.647 11.2Zm14.471 12.434h-3.387v-5.771c0-1.583-.601-2.467-1.853-2.467-1.362 0-2.073.92-2.073 2.467v5.771H13.54V12.64h3.265v1.481s.981-1.816 3.313-1.816c2.331 0 4 1.423 4 4.368v6.962Z" fill="#1851D7"/>
                            </g>
                          </svg>
                        </a>
                      }
                    </span>
                </div>
              </li>
          )
          })}
      </ul>
    </div>
  )
}

export default Champions;